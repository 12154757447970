import React from "react"
import Layout from '../containers/layout/layout'
import SEO from "../components/seo"
import Header from '../containers/layout/header/header-one'
import Footer from '../containers/layout/footer/footer-one'
import PageHeader from '../components/pageheader'
import {Container} from '../components/ui/wrapper/container';
import {Row} from '../components/ui/wrapper/row';
import {Col} from '../components/ui/wrapper/col';
import SectionTitle from '../components/ui/section-title';

const Support = ({pageContext, location}) => (
  <Layout location={location}>
    <SEO title="Support" />
    <Header/>
    <PageHeader
      pageContext={pageContext}
      location={location}
      title="Support"
    />
    <main className="site-wrapper-reveal">
      <Container>
        <Row mt="40px" mb="350px">
          <Col lg={12}>
            <SectionTitle
              mb="40px"
              title="Support Page"
              subtitle="This is"
            />
          </Col>
        </Row>
      </Container>
    </main>
    <Footer/>
  </Layout>
)

export default Support;
